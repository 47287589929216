import React, { useState } from "react";
import CSRImage1 from "../assets/csr-events/csr-1.jpg";
import CSRImage2 from "../assets/csr-events/csr-2.jpg";
import CSRImage3 from "../assets/csr-events/csr-3.jpg";
import CSRImage4 from "../assets/csr-events/csr-4.jpg";
import CSRImage5 from "../assets/csr-events/csr-5.jpg";
import CSR2Image1 from "../assets/csr-events-2/Csr-1.webp";
import CSR2Image2 from "../assets/csr-events-2/Csr-2.webp";
import CSR2Image3 from "../assets/csr-events-2/Csr-3.webp";
import CSR2Image4 from "../assets/csr-events-2/Csr-4.webp";
import CSR2Image5 from "../assets/csr-events-2/Csr-5.webp";
import CSR2Image6 from "../assets/csr-events-2/Csr-6.webp";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { IoIosArrowBack, IoIosArrowForward, IoMdClose } from "react-icons/io";
import { motion } from "framer-motion";
import { createSlideAnimation } from "../animations/animations";
import { Swiper as SwiperType } from "swiper";
import { Autoplay } from "swiper/modules";

const ImageDialog: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  imageSrc: string;
  allImages: string[];
}> = ({ isOpen, onClose, imageSrc, allImages }) => {
  const [swiper, setSwiper] = useState<SwiperType | null>(null);

  if (!isOpen) return null;
  const initialSlide = allImages.indexOf(imageSrc);

  return (
    // Image Dialog
    <div className="fixed inset-0 bg-black bg-opacity-60 flex items-center justify-center z-50 p-4 cursor-pointer px-2">
      <div className="bg-white rounded-lg w-full max-w-4xl max-h-[79vh] overflow-hidden relative">
        <button
          onClick={() => swiper?.slidePrev()}
          className="hidden md:block absolute left-4 top-1/2 -translate-y-1/2 z-10 bg-gray-400 bg-opacity-50 hover:bg-opacity-75 rounded-full p-3 text-white transition-colors duration-200"
          aria-label="Previous image"
        >
          <IoIosArrowBack size={24} />
        </button>
        <button
          onClick={() => swiper?.slideNext()}
          className="hidden md:block absolute right-4 top-1/2 -translate-y-1/2 z-10 bg-gray-400 bg-opacity-50 hover:bg-opacity-75 rounded-full p-3 text-white transition-colors duration-200"
          aria-label="Next image"
        >
          <IoIosArrowForward size={24} />
        </button>

        <Swiper
          initialSlide={initialSlide}
          loop={true}
          autoplay={{ delay: 2000, disableOnInteraction: false }}
          className=""
          onSwiper={setSwiper}
        >
          {allImages.map((img, index) => (
            <SwiperSlide key={index} className="">
              <div className="flex items-center justify-center">
                <img
                  src={img}
                  alt={`Enlarged view ${index + 1}`}
                  className="w-full h-full max-h-[79vh] object-cover"
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-white bg-gray-400 bg-opacity-50 hover:bg-opacity-75 rounded-full p-2 transition-colors duration-200 z-10"
          aria-label="Close"
        >
          <IoMdClose size={24} />
        </button>
      </div>
    </div>
  );
};

const KeyMoments: React.FC = () => {
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [currentImageSet, setCurrentImageSet] = useState<string[]>([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [mainSwiper, setMainSwiper] = useState<SwiperType | null>(null);
  const allImages = [CSRImage1, CSRImage2, CSRImage3, CSRImage4, CSRImage5];
  const allImages2 = [
    CSR2Image1,
    CSR2Image2,
    CSR2Image3,
    CSR2Image4,
    CSR2Image5,
    CSR2Image6,
  ];

  const handleFirstSlideImageClick = (img: string) => {
    setSelectedImage(img);
    setCurrentImageSet(allImages);
  };

  const handleSecondSlideImageClick = (img: string) => {
    setSelectedImage(img);
    setCurrentImageSet(allImages2);
  };

  const handleSlideNav = (direction: "prev" | "next") => {
    if (!mainSwiper) return;

    if (direction === "prev") {
      mainSwiper.slidePrev();
    } else {
      mainSwiper.slideNext();
    }
  };

  return (
    <div className="py-14 sm:py-16 md:py-20 lg:py-24">
      <div className=" flex justify-center">
        <div className="w-full flex flex-col items-start px-5 md:px-11">
          <h2 className="text-[36px] font-normal leading-[50px] text-[#302c51] font-prata text-center mb-16 w-full">
            Key Moments
          </h2>
          <p className="text-white font-medium leading-[30px] text-[25px] text-left mb-5 ml-1 bg-[#ed6221] px-4 py-2 rounded-md inline-block">
            CSR Events
          </p>
        </div>
      </div>
      <div className="relative">
        <button
          onClick={() => handleSlideNav("prev")}
          className="absolute left-0 top-1/2 -translate-y-1/2 z-10 bg-gray-400 bg-opacity-60 hover:bg-[#ed6221] hover:bg-opacity-75 rounded-full p-3 text-white transition-colors duration-200 hidden md:block"
          aria-label="Previous slide"
        >
          <IoIosArrowBack size={24} />
        </button>
        <button
          onClick={() => handleSlideNav("next")}
          className="absolute right-0 top-1/2 -translate-y-1/2 z-10 bg-gray-400 bg-opacity-60 hover:bg-[#ed6221] hover:bg-opacity-75 rounded-full p-3 text-white transition-colors duration-200 hidden md:block"
          aria-label="Next slide"
        >
          <IoIosArrowForward size={24} />
        </button>

        <div className="w-full px-5 md:px-12">
          <Swiper
            modules={[]}
            slidesPerView={1}
            spaceBetween={30}
            loop={true}
            className="w-full relative "
            onSwiper={setMainSwiper}
            onSlideChange={(swiper: SwiperType) =>
              setActiveIndex(swiper.realIndex)
            }
          >
            {/* First Slide */}
            <SwiperSlide>
              <div className="py-2 flex flex-col md:flex-row">
                <div className="md:w-1/2 md:h-2/3 mb-8 md:mb-0">
                  <div className="mb-4">
                    <img
                      src={CSRImage1}
                      alt="Key Moment"
                      className="w-full h-auto rounded-lg shadow-lg cursor-pointer"
                      onClick={() => handleFirstSlideImageClick(CSRImage1)}
                    />
                  </div>
                  <div className="mt-4 shadow-md cursor-pointer">
                    <Swiper
                      modules={[Autoplay]}
                      slidesPerView={3}
                      autoplay={{ delay: 2000, disableOnInteraction: false }}
                      spaceBetween={10}
                      loop={true}
                    >
                      {[CSRImage2, CSRImage3, CSRImage4, CSRImage5].map(
                        (img, index) => (
                          <SwiperSlide key={index}>
                            <img
                              src={img}
                              alt={`Key Moment ${index + 2}`}
                              className="w-full h-[80px] sm:h-[80px] md:h-[80px] lg:h-[120px] rounded-lg shadow-xl cursor-pointer
                              transition duration-300 ease-in-out transform hover:scale-95 "
                              onClick={() => handleFirstSlideImageClick(img)}
                            />
                          </SwiperSlide>
                        )
                      )}
                    </Swiper>
                  </div>
                </div>
                <div className="md:w-1/2 md:pl-8 mt-px">
                  <h3 className="text-xl sm:text-2xl md:text-3xl lg:text-[30px] font-normal leading-tight sm:leading-snug md:leading-normal lg:leading-[36px] text-[#302c51] mb-10 font-prata">
                    Empowering Underprivileged Youth through Japanese Language
                    Training
                  </h3>
                  <p className="text-[15px] leading-[28px] font-normal text-[#858585] mb-4">
                    <span className="font-medium text-gray-700">
                      Corporate Support:
                    </span>{" "}
                    Backed by
                    <span className="font-medium text-gray-700">
                      {" "}
                      Mitsui & Co. Pvt Ltd.
                    </span>{" "}
                    through their CSR initiative, this program is focused on
                    upskilling young individuals from marginalized communities,
                    providing them with the tools to unlock new career
                    possibilities.
                  </p>
                  <p className="text-[15px] leading-[28px] font-normal text-[#858585] mb-4">
                    <span className="font-medium text-gray-700">
                      Career Advancement:
                    </span>{" "}
                    Graduates of the program are offered exclusive employment
                    opportunities in Japan, paving the way for rewarding careers
                    abroad.
                  </p>
                  <p className="text-[15px] leading-[28px] font-normal text-[#858585] mb-4">
                    <span className="font-medium text-gray-700">
                      Making a Social Impact:
                    </span>{" "}
                    By addressing the skills gap and fostering inclusion, this
                    initiative transforms the lives of students from
                    economically disadvantaged backgrounds, equipping them to
                    succeed on the global stage.
                  </p>
                </div>
              </div>
            </SwiperSlide>

            {/* Second Slide */}
            <SwiperSlide>
              <div className="py-2 flex flex-col md:flex-row">
                <motion.div
                  className="md:w-1/2 md:pr-8 mt-5 order-2 md:order-1"
                  {...createSlideAnimation("left", 0.2)}
                >
                  <h3 className="text-xl sm:text-xl md:text-2xl lg:text-[30px] font-normal leading-tight sm:leading-snug md:leading-normal lg:leading-[36px] text-[#302c51] mb-10 font-prata">
                    Mitsui Client Visit for Inspiring Our Students to Aim Higher
                  </h3>
                  <p className="text-[15px] leading-[28px] font-normal text-[#858585] mb-4">
                    As our training program progresses,{" "}
                    <span className="font-medium text-gray-700">
                      Mitsui delegates paid us another visit,
                    </span>{" "}
                    showing their genuine care and support for our students.
                  </p>
                  <p className="text-[15px] leading-[28px] font-normal text-[#858585] mb-4">
                    They spent time engaging with the students,{" "}
                    <span className="font-medium text-gray-700">
                      sharing valuable advice and words of encouragement.
                    </span>{" "}
                    Their message was simple yet powerful, stay focused, keep
                    pushing forward, and give it your all.
                  </p>
                  <p className="text-[15px] leading-[28px] font-normal text-[#858585] mb-4">
                    <span className="font-medium text-gray-700">
                      The heartfelt conversations left a lasting impact, filling
                      the room with motivation and optimism.
                    </span>{" "}
                    Mitsui’s delegates reminded the students of their incredible
                    potential and wished them success in their upcoming exams
                    and future journeys.
                  </p>
                  <p className="text-[15px] leading-[28px] font-normal text-[#858585] mb-4">
                    Moments like these remind us of the{" "}
                    <span className="font-medium text-gray-700">
                      strength that comes from support,
                    </span>{" "}
                    as we continue to guide our students toward brighter
                    futures.
                  </p>
                </motion.div>
                <motion.div
                  className="md:w-1/2 mb-8 md:mb-0 order-1 md:order-2"
                  {...createSlideAnimation("right", 0.2)}
                >
                  <div className="mb-4">
                    <img
                      src={CSR2Image1}
                      alt="Key Moment"
                      className="w-full h-full rounded-lg shadow-lg cursor-pointer"
                      onClick={() => handleSecondSlideImageClick(CSR2Image1)}
                    />
                  </div>
                  <div className="mt-4 shadow-md cursor-pointer">
                    <Swiper
                      modules={[Autoplay]}
                      slidesPerView={3}
                      spaceBetween={10}
                      loop={true}
                      autoplay={{ delay: 2000, disableOnInteraction: false }}
                    >
                      {[
                        CSR2Image2,
                        CSR2Image3,
                        CSR2Image4,
                        CSR2Image5,
                        CSR2Image6,
                      ].map((img, index) => (
                        <SwiperSlide key={index}>
                          <img
                            src={img}
                            alt={`Key Moment ${index + 2}`}
                            className="w-full h-[80px] sm:h-[80px] md:h-[80px] lg:h-[120px] 2xl:h-[160px] rounded-lg shadow-xl cursor-pointer
                              transition duration-300 ease-in-out transform hover:scale-95 "
                            onClick={() => handleSecondSlideImageClick(img)}
                          />
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </div>
                </motion.div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
      <div className="flex justify-center gap-2 mt-7">
        {[0, 1].map((index) => (
          <div
            key={index}
            className={`w-8 h-1 rounded-full cursor-pointer transition-colors ${
              activeIndex === index ? "bg-[#ed6221]" : "bg-gray-300"
            }`}
            onClick={() => mainSwiper?.slideTo(index)}
          ></div>
        ))}
      </div>

      <ImageDialog
        isOpen={!!selectedImage}
        onClose={() => setSelectedImage(null)}
        imageSrc={selectedImage || ""}
        allImages={currentImageSet}
      />
    </div>
  );
};

export default KeyMoments;
