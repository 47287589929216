import { motion } from "framer-motion";
import React, { useState } from "react";
import { FaHome } from "react-icons/fa";
import { createSlideAnimation } from "../animations/animations";
import { FaPhoneAlt } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";

const Contact: React.FC = () => {
  const [showThankYouModal, setShowThankYouModal] = useState(false);
  const [showCallbackModal, setShowCallbackModal] = useState(false);
  const [callbackForm, setCallbackForm] = useState({
    companyName: "",
    name: "",
    phone: "",
  });

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = e.currentTarget;
    const formData = new FormData(form);

    try {
      const response = await fetch("https://formspree.io/f/xanwopdd", {
        method: "POST",
        body: formData,
        headers: {
          Accept: "application/json",
        },
      });

      if (response.ok) {
        setShowThankYouModal(true);
        form.reset();
      } else {
        alert("Oops! There was a problem submitting your form");
      }
    } catch (error) {
      alert("Oops! There was a problem submitting your form");
    }
  };

  const handleCallbackSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      const formData = new FormData();
      formData.append("companyName", callbackForm.companyName);
      formData.append("name", callbackForm.name);
      formData.append("phone", callbackForm.phone);
      formData.append("type", "Callback Request");

      const response = await fetch("https://formspree.io/f/xanwopdd", {
        method: "POST",
        body: formData,
        headers: {
          Accept: "application/json",
        },
      });

      if (response.ok) {
        setShowCallbackModal(false);
        setShowThankYouModal(true);
        setCallbackForm({
          companyName: "",
          name: "",
          phone: "",
        });
      } else {
        alert("Oops! There was a problem submitting your form");
      }
    } catch (error) {
      alert("Oops! There was a problem submitting your form");
    }
  };

  const closeModal = () => {
    setShowThankYouModal(false);
  };

  return (
    <div className="pb-28 md:pb-52">
      <div className="relative bg-[#1d1747e6] text-white py-28">
        <div className="text-center mb-12">
          <motion.h1
            className="font-prata font-normal text-[32px] md:text-[48px] leading-[58px]"
            {...createSlideAnimation("bottom", 0.2)}
          >
            Get in Touch With Us
          </motion.h1>
        </div>

        <div className="absolute w-[205px] h-[63px] bottom-0 left-6 md:left-11 transform translate-y-1/2 bg-white py-2 px-4 rounded shadow-md flex items-center justify-center">
          <span className="text-[#ff6b35] mr-2 text-[24px]">
            <FaHome />
          </span>
          <div className="w-px h-7 bg-[#EAEAEA] mx-4"></div>
          <span className="font-normal text-[16px] leading-[28px] text-[#1d1747e6] mt-2">
            Contact
          </span>
        </div>
      </div>

      {/* Thank You Modal */}
      {showThankYouModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full">
            <h2 className="text-2xl font-bold mb-4 text-[#302c51]">
              Thank You!
            </h2>
            <p className="text-[#858585] mb-6">
              Your message has been successfully sent. We appreciate your
              contact and will get back to you soon.
            </p>
            <button
              onClick={closeModal}
              className="w-full bg-[#ed6221] text-white py-2 px-4 rounded-full hover:bg-[#d55a1e] transition-colors duration-300"
            >
              Close
            </button>
          </div>
        </div>
      )}

      {/* Callback Modal */}
      {showCallbackModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full">
            <h2 className="text-2xl font-bold mb-4 text-[#302c51]">
              Request a Callback
            </h2>
            <form onSubmit={handleCallbackSubmit}>
              <div className="mb-4">
                <input
                  type="text"
                  placeholder="Company Name"
                  value={callbackForm.companyName}
                  onChange={(e) =>
                    setCallbackForm({
                      ...callbackForm,
                      companyName: e.target.value,
                    })
                  }
                  className="w-full px-5 py-4 bg-white text-[#848484] text-[16px] font-light leading-[30px] rounded border border-[#EAEAEA] focus:border-orange-500 focus:outline-none"
                  required
                />
              </div>
              <div className="mb-4">
                <input
                  type="text"
                  placeholder="Your Name"
                  value={callbackForm.name}
                  onChange={(e) =>
                    setCallbackForm({ ...callbackForm, name: e.target.value })
                  }
                  className="w-full px-5 py-4 bg-white text-[#848484] text-[16px] font-light leading-[30px] rounded border border-[#EAEAEA] focus:border-orange-500 focus:outline-none"
                  required
                />
              </div>
              <div className="mb-6">
                <input
                  type="tel"
                  placeholder="Phone Number"
                  value={callbackForm.phone}
                  onChange={(e) =>
                    setCallbackForm({ ...callbackForm, phone: e.target.value })
                  }
                  className="w-full px-5 py-4 bg-white text-[#848484] text-[16px] font-light leading-[30px] rounded border border-[#EAEAEA] focus:border-orange-500 focus:outline-none"
                  required
                />
              </div>
              <div className="flex justify-end gap-4">
                <button
                  type="button"
                  onClick={() => setShowCallbackModal(false)}
                  className="px-6 py-2 text-gray-600 hover:text-gray-800"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-6 py-2 bg-[#ed6221] text-white rounded-full hover:bg-[#d55a1e] transition-colors duration-300"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      <div className="container mx-auto px-5 lg:px-11 pt-[110px]">
        <motion.h2
          className="font-normal text-[36px] leading-[50px] text-[#302c51] font-prata mb-8 text-left"
          {...createSlideAnimation("left", 0.2)}
        >
          Drop Us a Message
        </motion.h2>
        <div className="flex flex-col md:flex-row justify-center">
          {/* contact form */}
          <motion.div
            className="w-full md:w-2/3 mb-8 lg:mb-0"
            {...createSlideAnimation("bottom", 0.4)}
          >
            <form onSubmit={handleSubmit}>
              <div className="flex flex-col md:flex-row mb-8">
                {/* Name */}
                <div className="w-full md:w-1/2 px-2 mb-4 md:mb-0">
                  <input
                    type="text"
                    name="name"
                    placeholder="Name"
                    className="w-full px-5 py-4 bg-white text-[#848484] text-[16px] font-light leading-[30px] rounded border border-[#EAEAEA] focus:border-orange-500 focus:outline-none"
                    required
                  />
                </div>
                {/* Email */}
                <div className="w-full md:w-1/2 px-2">
                  <input
                    type="email"
                    name="email"
                    placeholder="Email"
                    className="w-full px-5 py-4 bg-white text-[#848484] text-[16px] font-light leading-[30px] rounded border border-[#EAEAEA] focus:border-orange-500 focus:outline-none"
                    required
                  />
                </div>
              </div>
              <div className="flex flex-col md:flex-row mb-8">
                {/* Phone Number */}
                <div className="w-full md:w-1/2 px-2 mb-4 md:mb-0">
                  <input
                    type="tel"
                    name="phone"
                    placeholder="Phone"
                    className="w-full px-5 py-4 bg-white text-[#848484] text-[16px] font-light leading-[30px] rounded border border-[#EAEAEA] focus:border-orange-500 focus:outline-none"
                  />
                </div>
                {/* Subject */}
                <div className="w-full md:w-1/2 px-2">
                  <input
                    type="text"
                    name="subject"
                    placeholder="Subject"
                    className="w-full px-5 py-4 bg-white text-[#848484] text-[16px] font-light leading-[30px] rounded border border-[#EAEAEA] focus:border-orange-500 focus:outline-none"
                  />
                </div>
              </div>
              {/* Message */}
              <div className="mb-8 px-2">
                <textarea
                  name="message"
                  placeholder="Message..."
                  rows={5}
                  className="w-full p-5 bg-white text-[#848484] text-[16px] font-light leading-[30px] rounded border border-[#EAEAEA] focus:border-orange-500 focus:outline-none"
                  required
                ></textarea>
              </div>
              <div className="flex flex-col lg:flex-row px-2">
                {/* Send Message button */}
                <button
                  type="submit"
                  className="w-full md:w-[198px] mt-2 sm:mt-4 flex justify-center items-center bg-transparent border-2 border-[#ed6221] rounded-full relative overflow-hidden group py-4"
                >
                  <span className="relative z-10 text-[#ed6221] group-hover:text-white transition-colors duration-300 text-[13px] font-[500] leading-[24px] uppercase">
                    Send Message
                  </span>
                  <span className="absolute inset-0 bg-[#ed6221] transform -translate-y-full group-hover:translate-y-0 transition-transform duration-300"></span>
                </button>
                <p className="text-[15px] text-[#302c51] font-normal leading-[28px] mt-5 md:mt-8 md:ml-5 md:text-left">
                  *Get answers to common questions through our help center.
                </p>
              </div>
            </form>
          </motion.div>
          <div className="pl-0 md:pl-6">
            <motion.div
              className="w-full md:max-w-[369px] px-4 bg-white shadow-[0px_20px_48px_2px_rgba(0,0,0,0.1)] rounded-lg my-0 md:-my-20"
              {...createSlideAnimation("right", 0.6)}
            >
              <div className="bg-white text-gray-800 px-8 py-5">
                <h3 className="text-[24px] font-normal leading-[29px] text-[#302c51] mt-8 mb-4">
                  Registered Office :
                </h3>
                <p className="mb-6 font-normal text-[15px] leading-[28px] text-[#858585]">
                  No. 8, 2nd floor, 6th cross, Sathyanagar, Puducherry - 605013
                </p>
                <a
                  href="https://maps.app.goo.gl/11HV7KiS48dukWMM8"
                  className="text-[#ed6221] uppercase text-[13px] leading-[28px] font-medium"
                >
                  Your nearest location
                </a>
                <hr className="my-6 border-[#EAEAEA]" />
                <h3 className="text-[24px] font-normal leading-[29px] text-[#302c51] mt-8 mb-4">
                  Representative Office :
                </h3>
                <p className="mb-6 font-normal  text-[15px] leading-[28px] text-[#858585]">
                  JP Chambers, No 276/22/1, 46th Cross, 5th Block Jayanagar,
                  Bengaluru 560041, Karnataka Tel: +91-90350 53109
                </p>
                <a
                  href="https://maps.app.goo.gl/11HV7KiS48dukWMM8"
                  className="text-[#ed6221] uppercase text-[13px] leading-[28px] font-medium"
                >
                  Your nearest location
                </a>
                <hr className="my-6 border-[#EAEAEA]" />
                <h3 className="text-[24px] font-normal leading-[29px] text-[#302c51] mt-8 mb-4">
                  Quick Contact
                </h3>
                <p className="mb-2 font-normal text-[15px] leading-[28px] text-[#858585] flex items-center">
                  <IoMdMail className="mr-2 text-[#858585]" />
                  <a href="mailto:contact@valuewings.com">
                    contact@valuewings.com
                  </a>
                </p>
                <p className="mb-6 font-normal text-[15px] leading-[28px] text-[#858585] flex items-center hover:text-right">
                  <FaPhoneAlt className="mr-2 text-[#858585]" />
                  <a href="tel:+919035053109">+91 90350 53109</a>
                </p>
                <a
                  href="/"
                  onClick={(e) => {
                    e.preventDefault();
                    setShowCallbackModal(true);
                  }}
                  className="text-[#ed6221] uppercase text-[13px] leading-[28px] font-medium mb-4"
                >
                  Get call back
                </a>
              </div>
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
