import React from "react";
import TopBar from "../component/TopBar";
import NavBar from "../component/NavBar";
import AboutUs from "../component/AboutUs";
import ContactUs from "../component/Contact";
import Footer from "../component/Footer";
import OurTeam from "../component/OurTeam";
import { FaWhatsapp } from "react-icons/fa";

const AboutUsPage: React.FC = () => {
  return (
    <div>
      <TopBar />
      <NavBar />
      <AboutUs />
      <OurTeam />
      <ContactUs />
      <Footer />
      <a
        href="https://wa.me/919035053109?text=Hi!%20I%27d%20like%20to%20know%20more%20about%20your%20programs"
        className="fixed bottom-6 right-6 bg-green-500 text-white p-3 rounded-full shadow-lg hover:bg-green-600 transition-all z-50"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Chat on WhatsApp"
      >
        <FaWhatsapp size={40} />
      </a>
    </div>
  );
};

export default AboutUsPage;
